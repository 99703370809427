import React, { useEffect } from "react"
import SEO from "../../components/seo"

// 🎨 UI
import SinglePageWrapper from "../../components/Contents/SinglePageWrapper"
import ProjektzielTeaserImage from "../../components/Contents/Projektziel/ProjektzielTeaserImage"
import ProjektzielHeader from "../../components/Contents/Projektziel/ProjektzielHeader"
import ScrollTopButton from "../../components/ScrollTopButton"
import { graphql } from "gatsby"
import Inhalt from "../../components/Contents/Inhalt"
import { useDispatch } from "react-redux"

export default function SinglePage({ data }) {
  const post = data.wpPage

  const sendActionToStore = useDispatch()

  useEffect(() => {
    sendActionToStore({
      type: "change/translatedProjectSlug",
      payload: post.translated[0]?.slug,
    })
  }, [post.translated, sendActionToStore])
  // console.log("POST", post)
  return (
    <>
      <SEO title={post.title} />
      <ScrollTopButton />

      {/* 👤 Header Detailseite */}
      <SinglePageWrapper background="blue.50">
        <ProjektzielHeader
          schwerpunkt={post.acf_page.textSubtitle}
          title={post.title}
        />
        {post.acf_page.imageFeaturedImage && (
          <ProjektzielTeaserImage image={post.acf_page.imageFeaturedImage} />
        )}

        {/* 📰 Inhalt */}
        <Inhalt
          repeater={post.acf_content.repeaterDetails}
          smallWidth={true}
          projektZiel={true}
          onColor={true}
        />
      </SinglePageWrapper>
    </>
  )
}

export const query = graphql`
  query SinglePageQuery($lang: String!, $slug: String!) {
    wpPage(locale: { locale: { eq: $lang } }, slug: { eq: $slug }) {
      title
      translated {
        slug
      }
      acf_page {
        textSubtitle
        imageFeaturedImage {
          localFile {
            childImageSharp {
              fluid(quality: 60) {
                src
                srcSet
              }
            }
            publicURL
          }
        }
      }
      acf_content {
        repeaterDetails {
          textSectionTitel
          flexibleLayoutContentarea {
            ... on WpPage_AcfContent_repeaterDetails_FlexibleLayoutContentarea_Link {
              fieldGroupName
              linkText
              linkUrl
            }
            ... on WpPage_AcfContent_repeaterDetails_FlexibleLayoutContentarea_AbsatzMitGallerie {
              wysiwygContent
              groupGallery {
                galleryGallery {
                  altText
                  localFile {
                    childImageSharp {
                      fluid(quality: 60) {
                        src
                        srcSet
                      }
                    }
                    publicURL
                  }
                  acf_media {
                    textImageCredit
                  }
                }
                columns
              }
              linkText
              linkUrl
              fieldGroupName
            }
            ... on WpPage_AcfContent_repeaterDetails_FlexibleLayoutContentarea_Absatz {
              wysiwygContent
              fieldGroupName
              linkText
              linkUrl
            }
            ... on WpPage_AcfContent_repeaterDetails_FlexibleLayoutContentarea_VerwandteProjekte {
              fieldGroupName
              relationProject {
                ... on WpProjektziel {
                  id
                  slug
                  acf_projektziel_content {
                    groupLeft {
                      relationMobilitaetsschwerpunkt {
                        ... on WpSchwerpunkt {
                          id
                          slug
                        }
                      }
                      imageFeaturedImage {
                        altText
                        localFile {
                          childImageSharp {
                            fluid(quality: 60) {
                              src
                              srcSet
                            }
                          }
                          publicURL
                        }
                      }
                      textSummary
                      textProjektname
                    }
                    fieldGroupName
                  }
                }
              }
            }
            ... on WpPage_AcfContent_repeaterDetails_FlexibleLayoutContentarea_VerwandteForschung {
              fieldGroupName
              relationForschung {
                ... on WpWissenschaft {
                  id
                  acf_forschung {
                    relationSchwerpunktthema {
                      ... on WpWissenschaftsschwerpunkt {
                        id
                        slug
                        title
                      }
                    }
                    projektbeschreibung {
                      fieldGroupName
                      textProjektbeschreibungText
                      textProjektbeschreibungTitle
                    }
                    imageFeatureImage {
                      altText
                      localFile {
                        childImageSharp {
                          fluid(quality: 60) {
                            src
                            srcSet
                          }
                        }
                        publicURL
                      }
                    }
                    groupProjektbeteiligte {
                      disziplin {
                        name
                      }
                    }
                  }
                  title
                  slug
                }
              }
            }
            ... on WpPage_AcfContent_repeaterDetails_FlexibleLayoutContentarea_Registerkarten {
              fieldGroupName
              repeaterRegisterkarten {
                textContent
                textTitle
              }
            }
            ... on WpPage_AcfContent_repeaterDetails_FlexibleLayoutContentarea_Download {
              fieldGroupName
              anhang {
                ... on WpPage_AcfContent_repeaterDetails_FlexibleLayoutContentarea_Download_Anhang_Datei {
                  buttonText
                  fieldGroupName
                  datei {
                    localFile {
                      publicURL
                    }
                  }
                }
                ... on WpPage_AcfContent_repeaterDetails_FlexibleLayoutContentarea_Download_Anhang_Video {
                  buttonText
                  fieldGroupName
                  video {
                    localFile {
                      publicURL
                    }
                  }
                }
                ... on WpPage_AcfContent_repeaterDetails_FlexibleLayoutContentarea_Download_Anhang_Url {
                  buttonText
                  fieldGroupName
                  url
                }
              }
            }
          }
          fieldGroupName
        }
      }
    }
  }
`
